import * as Sentry from '@sentry/react'
import { DefaultError } from '@tanstack/react-query'
import { AxiosError } from 'axios'

type SupplementedAxiosError = AxiosError & {
  pathTemplate?: string
  pathParams?: Record<string, string>
}

export const logRequestError = (
  errorIn: SupplementedAxiosError | DefaultError,
  _extra?: Record<string, any>
) => {
  // @ts-ignore if isAxiosError is present then it's an axios error
  if (!!errorIn?.isAxiosError) {
    const error = errorIn as SupplementedAxiosError
    if (!error.config) {
      Sentry.captureException(error, {
        extra: { logRequestError: 'error.config undefined', ..._extra },
      })
      return
    }

    const extra: Record<string, any> = { ..._extra }

    const requestPath = error.pathTemplate || error.config.url
    const bestGuessGenericUrl = requestPath?.includes('mppf')
      ? requestPath
          .split('/')
          .map(urlSegment => (urlSegment.includes('mppf') ? ':param' : urlSegment))
          .join('/')
      : requestPath

    const errorName = `${error.config.method?.toUpperCase()} ${bestGuessGenericUrl} ${
      error.response?.status
    }`
    if (
      error.response?.data &&
      error.response?.status !== 401 // no user data for expired tokens
    ) {
      extra.response = error.response?.data
    }
    if (error.pathParams) {
      extra.pathParams = error.pathParams
    }

    error.name = errorName

    if (import.meta.env.PROD || import.meta.env.VITE_DEBUG_SENTRY) {
      Sentry.captureException(error, { extra })
    } else {
      console.error(`logRequestError: "${errorName}"`)
    }
  } else {
    if (import.meta.env.PROD || import.meta.env.VITE_DEBUG_SENTRY) {
      const error = errorIn
      error.name = !!_extra
        ? `error for ${_extra?.firstQueryKey || _extra?.firstMutationKey} - ${
            _extra.queryKeyLength
          }`
        : 'unknown error provenance'
      // any non-sentry error, specifically this may be an error passed through by react-query, though that's somewhat unlikely
      Sentry.captureException(error, {
        extra: _extra,
      })
      return
    } else {
      console.error(`logRequestError: "${_extra}"`)
    }
  }
}
